import * as React from "react";
import { Typography, Container, Box, Button, IconButton, Fab } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { GridFromQueryInput, GridFromQueryInputProps } from "../query-to-grid/gridFromQueryInput";
import { GeneralPersonaExamples } from "./generalPersonaExamples";
import { CompanyPersonaExamples } from "./companyPersonaFilters";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchPersona } from "./personas";
import { PersonaPicker } from "./personaPicker";
import { EditableCrunchbaseFilter } from "./filters/types";
import { useTrackPageView } from "../mixpanel";
import { Grid2 } from "iconsax-react";
import { useBoolean } from "../utils/hooks";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { GridBuildWithQueryAndFiltersRequestFilter, GridCreateRequest } from "../services/cb-backend-types";
import { convertFrontendGridDataToGridData } from "../grid/gridSerialization";
import { useCurrentUserQuery } from "../context/userContext";
import { Close, PlayArrow } from "@mui/icons-material";
import Wave from "../assets/wave.gif";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { UpgradePlanDialog } from "../settings/upgradeDialog";
import { FREE_TRIAL_QUERY_KEY_FIRST_PART } from "../react-query/queryKeys";
import { getPlanFromUserPlanType, UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY } from "../grid/grid";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { updateAvailableGridsOnCreate } from "../context/gridContextAndProvider";

export const HomeView: React.FC = () => {
    const user = useCurrentUserQuery();

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [isRedirecting, setIsRedirecting] = React.useState(false);

    React.useEffect(() => {
        if (!isLoggedIn() && !isRedirecting) {
            setIsRedirecting(true);
            navigate("/try-it");
        }
    }, [isRedirecting, navigate]);

    useTrackPageView();

    const [persona, setPersona] = React.useState<SearchPersona>("companies");

    const [query, setQuery] = React.useState<string>("");
    const { value: isLoadingAfterQuery, setTrue: startLoading, setFalse: stopLoading } = useBoolean(false);

    const [filters, setFilters] = React.useState<EditableCrunchbaseFilter[]>([]);

    const filtersInfo = React.useMemo<GridFromQueryInputProps["filterInfo"]>(
        () =>
            persona === "companies"
                ? {
                      filters,
                      setFilters,
                      persona,
                  }
                : undefined,
        [filters, persona],
    );

    const handleQuerySubmit = useHandleSubmitQueryAndFilters(startLoading, stopLoading, persona);

    const service = React.useMemo(() => getBackendService(), []);
    const queryClient = useQueryClient();
    const handleCreateNewGrid = React.useCallback(async () => {
        const request: GridCreateRequest = {
            name: "New empty exploration",
            data: convertFrontendGridDataToGridData([], [], 0),
            row_generating_filters: {
                filters: [],
                paging_info: undefined,
            },
            visibility_filters: {
                filters_by_column: {},
            },
        };
        try {
            const newGrid = await service.createGrid(request);
            updateAvailableGridsOnCreate(queryClient, newGrid);
            if (newGrid.unique_id != null) {
                navigate(`/grid/${newGrid.unique_id}`, { replace: true });
            }
        } catch (error) {
            console.error("Error creating grid", error);
        }
    }, [service, queryClient, navigate]);

    const handleClickSuggestedQuery = React.useCallback(
        (query: string) => {
            setQuery(query);
            void handleQuerySubmit(query, filters);
        },
        [filters, handleQuerySubmit],
    );

    const { value: isUpgradeDialogOpen, setTrue: openUpgradeDialog, setFalse: closeUpgradeDialog } = useBoolean(false);

    React.useEffect(() => {
        if (searchParams.has("welcome")) {
            setSearchParams({});

            const plan = localStorage.getItem(UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY);
            localStorage.removeItem(UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY);
            if (plan != null && plan !== getPlanFromUserPlanType("free-trial")) {
                openUpgradeDialog();
            }
        }
    }, [searchParams, openUpgradeDialog, setSearchParams]);

    return (
        <>
            <SidebarLayout selectedGridId={undefined}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        bgcolor: "primary.main",
                        borderRadius: 3,
                        border: 1,
                        borderColor: "#DEDEDE",
                    }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            alignSelf: "end",
                            marginRight: "16px",
                        }}
                    >
                        {user.data?.is_admin ?? false ? (
                            <Button
                                variant="outlined"
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={handleCreateNewGrid}
                                size="small"
                                startIcon={<Grid2 size={16} />}
                                sx={{
                                    borderRadius: 8,
                                    color: "secondary.main",
                                    textTransform: "none",
                                    typography: "caption",
                                    boxShadow: "none",
                                    border: "1px solid #EAECF0",
                                }}
                            >
                                New Empty Grid
                            </Button>
                        ) : null}
                    </Box>
                    <Container
                        maxWidth="sm"
                        sx={{
                            alignContent: "center",
                            flexGrow: 1,
                            minWidth: "712px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "stretch",
                                alignItems: "stretch",
                                pb: 8,
                                overflowX: "hidden",
                                maxWidth: "100%",
                            }}
                        >
                            <VerticalAnswerGridLogo />
                            <PersonaPicker selectedPersona={persona} onSelectPersona={setPersona} />
                            <GridFromQueryInput
                                isLoadingAfterQuery={isLoadingAfterQuery}
                                onQueryChange={setQuery}
                                onQuerySubmit={handleQuerySubmit}
                                placeholder={PLACEHOLDER_BY_PERSONA[persona]}
                                query={query}
                                filterInfo={filtersInfo}
                                sx={{
                                    overflowX: "hidden",
                                }}
                            />
                            <PersonaFilterOrExampleSection
                                persona={persona}
                                onQuerySubmit={handleClickSuggestedQuery}
                            />
                        </Box>
                    </Container>
                </Box>
                <FloatingComponent />
            </SidebarLayout>
            <UpgradePlanDialog open={isUpgradeDialogOpen} onClose={closeUpgradeDialog} />
        </>
    );
};

const VerticalAnswerGridLogo: React.FC = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                rowGap: 1.5,
                mb: 4,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Logo
                    style={{
                        width: 40,
                        height: 40,
                    }}
                />
            </Box>
            <Typography
                variant="h3"
                sx={{
                    color: "secondary.main",
                    fontFamily: "Garamond-Light-Cond",
                }}
            >
                Answers at scale
            </Typography>
        </Box>
    );
};

interface PersonaFilterOrExampleSection {
    persona: SearchPersona;
    onQuerySubmit: (q: string) => void;
}

export const PersonaFilterOrExampleSection: React.FC<PersonaFilterOrExampleSection> = ({ persona, onQuerySubmit }) => {
    switch (persona) {
        case "companies":
            return <CompanyPersonaExamples onQuerySubmit={onQuerySubmit} />;
        case "general":
            return <GeneralPersonaExamples />;
    }
};

const PLACEHOLDER_BY_PERSONA: Record<SearchPersona, string | undefined> = {
    companies: "Find companies…",
    general: undefined,
};

const useHandleSubmitQueryAndFilters = (
    onStartLoading: () => void,
    onFinishLoading: () => void,
    persona: SearchPersona,
) => {
    const backendService = React.useMemo(() => getBackendService(), []);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const queryClient = useQueryClient();
    const buildCompanyGridMutation = useMutation({
        mutationFn: (args: { q: string; submittedFilters: EditableCrunchbaseFilter[] }) => {
            return backendService.buildCompanyGridWithQueryAndFilters({
                query: args.q,
                filters: args.submittedFilters.map<GridBuildWithQueryAndFiltersRequestFilter>(f => ({
                    predicate: f.filter,
                    add_as_column: f.addAsColumn,
                })),
            });
        },
        onMutate: onStartLoading,
        onError: () => {
            onFinishLoading();
            enqueueSnackbar(
                "We didn't manage to convert your query into a set of filters. Please try a different query or try using the filters drop-down.",
                {
                    variant: "info",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                },
            );
        },
        onSuccess: grid => {
            onFinishLoading();
            updateAvailableGridsOnCreate(queryClient, grid);
            void queryClient.invalidateQueries({ queryKey: FREE_TRIAL_QUERY_KEY_FIRST_PART });
            navigate(`/grid/${grid.unique_id}`);
        },
    });

    const handleQuerySubmit = React.useCallback(
        async (q: string, submittedFilters: EditableCrunchbaseFilter[]) => {
            onStartLoading();

            try {
                switch (persona) {
                    case "companies": {
                        await buildCompanyGridMutation.mutateAsync({ q, submittedFilters });
                        break;
                    }
                    case "general": {
                        // TODO: Implement general search
                        console.log(`Searching generally with query: ${q}`);
                        break;
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                onFinishLoading();
            }
        },
        [buildCompanyGridMutation, onFinishLoading, onStartLoading, persona],
    );

    return handleQuerySubmit;
};

const LOCAL_STORAGE_KEY = "answerGrid.floatingComponentOpen";

const FloatingComponent: React.FC = () => {
    const [open, setOpen] = React.useState(localStorage.getItem(LOCAL_STORAGE_KEY) !== "false");

    const handleClose = React.useCallback(() => {
        setOpen(false);
        localStorage.setItem(LOCAL_STORAGE_KEY, "false");
    }, []);

    const handleRedirect = () => {
        window.location.href =
            "https://www.loom.com/share/5714e03a342e401092d093b95cc7c33b?sid=744c2e0f-dccd-4c15-bbd0-23babd4c1141";
    };

    if (!open) return null;

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                width: 200,
                backgroundColor: "white",
                boxShadow: 3,
                borderRadius: 2,
                overflow: "hidden",
                zIndex: 1000,
                p: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pl: 1,
                }}
            >
                <Typography
                    variant="body1"
                    onClick={handleRedirect}
                    sx={{ cursor: "pointer" }}
                    color="secondary.main"
                    fontWeight="550"
                >
                    How it works
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <IconButton size="small" onClick={handleClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    src={Wave}
                    alt="How it works"
                    onClick={handleRedirect}
                    sx={{
                        cursor: "pointer",
                        width: "100%",
                        height: "auto",
                        // margin: "5px",
                        border: "none",
                        borderRadius: "50%",
                        clipPath: "inset(3px)",
                    }}
                />
                <Fab
                    size="small"
                    href="https://www.loom.com/share/5714e03a342e401092d093b95cc7c33b?sid=744c2e0f-dccd-4c15-bbd0-23babd4c1141"
                    target="_blank"
                    sx={{
                        opacity: 0.8,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <PlayArrow />
                </Fab>
            </Box>
        </Box>
    );
};
